export default class FaqModel {
  constructor(){
    this.objRaw = {};
    this.membershipId = null;
    this.categoryType = 1;
    this.mapId = null;
    this.count = null;
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { membershipId, categoryType, mapId, count } = obj;
    this.membershipId = membershipId;
    this.categoryType = categoryType;
    this.mapId = mapId;
    this.count = count;
  }
  getData(){
    let obj = {
      membershipId : this.membershipId,
      categoryType : this.categoryType,
      mapId : this.mapId,
      count : this.count,
    }
    return obj;
  }
}