<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">리딤코드 생성</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px" />
        <col />
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>
            categoryType<IconSvg
              iconName="essential"
              :size="8"
              iconColor="#F04848"
            />
          </th>
          <td>
            <Selectbox
              maxWidth="300px"
              :dataList="'redeem_category_type' | getSelectDataList"
              :value.sync="viewModel.model.categoryType"
            />
          </td>
        </tr>
        <tr>
          <th>
            membershipId<IconSvg iconName="essential" :size="8" iconColor="#F04848" />
          </th>
          <td>
            <Input placeholder="membership id" :value.sync="viewModel.model.membershipId" />
          </td>
        </tr>
        <tr>
          <th>
            mapId<IconSvg iconName="essential" :size="8" iconColor="#F04848" />
          </th>
          <td>
            <Input placeholder="map id" :value.sync="viewModel.model.mapId" />
          </td>
        </tr>
        <tr>
          <th>
            count<IconSvg iconName="essential" :size="8" iconColor="#F04848" />
          </th>
          <td>
            <Input
              placeholder="생성할 리딤코드 개수"
              :value.sync="viewModel.model.count"
            />
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="SERVICE_REDEEM_LIST"
          :routeQuery="{
            // status: viewModel.model.tabStatus,
            pageSize: $route.query.pageSize,
            page: $route.query.page,
          }"
        />
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="생성"
          :disabled="!isVaild"
          @onClickBtn="viewModel.submit()"
        />
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';

import RedeemRegisterViewModel from '@/views/service/redeem/viewModel/RedeemRegisterViewModel';

import { canBeNumber } from '@lemontree-ai/lemontree-admin-common-front/utils/numberUtils';

export default {
  name: 'RedeemRegister',
  components: {
    PageWithLayout,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    Sticky,
    Button,
  },
  data() {
    return {
      viewModel: new RedeemRegisterViewModel(),
    };
  },
  computed: {
    isVaild() {
      if (!canBeNumber(this.viewModel.model.categoryType)) return false;
      if (!canBeNumber(this.viewModel.model.mapId)) return false;
      if (!canBeNumber(this.viewModel.model.count)) return false;

      const count = Number(this.viewModel.model.count);

      if (count < 1 || count > 1000000) return false;

      return true;
    },
  },
};
</script>
<style scoped>
.desc_code_name_result {
  margin-left: 8px;
  font-weight: 600;
  font-size: 14px;
}
.desc_code_name_result .icon_firfin {
  margin: 12px 2px 0 0;
}

.switch_comm {
  display: block;
}
.switch_comm + .group_form {
  margin-top: 4px;
}
</style>
