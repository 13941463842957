import router from '@/router';
import { POST_REQUEST } from '@/services/http';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';
import RedeemRegisterModel from '@/views/service/redeem/model/RedeemRegisterModel';

export default class RedeemRegisterViewModel {
  constructor() {
    this.model =  new RedeemRegisterModel();
  }

  submit() {
    const {membershipId, categoryType, mapId, count} = this.model.getData();
    const path = `${apiPath.REDEEM_REGISTER.format(membershipId, categoryType, mapId, count)}`;
    POST_REQUEST(path).then(
      success => {
        // const resultData = success.data;
        store.dispatch(
          'commonToast/fetchToastStart',
          `리딤코드 생성이 완료되었습니다.`,
        );
        router.push({ name: 'SERVICE_REDEEM_LIST' });
      },
      failed => {
        store.dispatch('commonToast/fetchToastStart', failed.msg);
      },
    );
  }
}
